import main from '../assets/images/main2.jpg';
import Wrapper from '../assets/wrappers/LandingPage';
import {Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Typed from 'react-typed';

import bgVideo2 from '../assets/videos/bg10.m4v';
import bgVideo from '../assets/videos/bg10.webm';
import personVideo from '../assets/videos/testfinal.webm';
import testVideo from '../assets/videos/testres.webm';
import bgImg from "../assets/images/bg.jpg";
import aera from "../assets/images/aera2-09.png";


const Landing = () => {
  const queryParams = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    response_type: "token",
    scope: process.env.REACT_APP_COGNITO_SCOPES,
    redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT
  }

  const cognitoUiUrl = new URL(process.env.REACT_APP_COGNITO_UI) + "?" + decodeURIComponent(new URLSearchParams(queryParams).toString());

  return (
    <Wrapper>
      <div className='container'>
        <video autoPlay playsInline muted loop id="myVideo">
          <source src={bgVideo} type="video/mp4"/>
          <source src={bgVideo2} type="video/mp4"/>
        </video>
        <img src={bgImg} id='backgroundImage' alt="bg"/>
        {/* info */}
      </div>
      <div className="contentContainer">
        <p className='text-style-5 text-white' id='meetText'>MEET</p>
        <img src={aera} id='aeraImage' alt="bg"/>
        <p className='text-style-6' id='friendText'>your virtual <Typed
          strings={['friend', 'coach', 'assistant', 'tutor', 'teacher', 'customer support', 'model']}
          loop={true}
          typeSpeed={80}
          backSpeed={80}
        /></p>
        <p className='text-style-9' id='friendText'>AI avatar that can engage in real-time visual/audio chat</p>
        <p className='text-style-13' id='captionText'>talk to her about your problems, life issues,</p>
        <p className='text-style-13' id='captionText'>and whatever rouses your curiosities.</p>
        <Button type="button" className="my-3 theme-color">
          <Link to="/run" className="text-white text-decoration-none" id="linkText">
            Try now! (Beta)
          </Link>
        </Button>
      </div>
    </Wrapper>
  );
};

export default Landing;
