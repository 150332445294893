import {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
import Loader from 'react-dots-loader';
import 'react-dots-loader/index.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import {VideoDisplay, FormRow} from '../components';
import Wrapper from '../assets/wrappers/RunPage';
import {ChatBox} from 'react-chatbox-component';
import bgVideo from '../assets/videos/bg10.webm'
import bgVideo2 from '../assets/videos/bg10.m4v'
import personVideo2 from '../assets/videos/testloop8_bg.mp4';


import userHuman from '../assets/images/human_user-04.svg';
import userAi from '../assets/images/ai_user-03.svg';
import userError from '../assets/images/error_user-05.svg';
import "../assets/css/chatboxstyle.css";
import styles from './Run.module.css';
import bgImg from "../assets/images/bg.jpg";


const initialState = {
  speech: 'Hi, I am Mona'
};

const initialVideo = personVideo2;

const startMessage = [
  {
    "text": "Hi!",
    "id": "1",
    "sender": {
      "name": "Aera",
      "uid": "user0",
      "avatar": userAi,
    },
  },
]


const Run = () => {
  const {user} = useSelector((store) => store?.user);
  const kishiUrl = process.env.REACT_APP_API;
  const accessToken = user?.token;
  const userName = user?.email;
  let rqId = "";
  const apiKey = "4PK85KGB3NXT1PZMMK08FMRWVBM9USVLA74BSBPL";

  const [chatLoad, setChatLoad] = useState(false);
  const [typeLoad, setTypeLoad] = useState(null);

  const [mute, setMute] = useState(true);
  const [messages, setMessages] = useState(startMessage);

  const vidRef = useRef();
  const [videoSource, setVideoSource] = useState(initialVideo);
  const [looping, setLoop] = useState(true);

  const poll = async function (fn, fnCondition, ms) {
    let result = await fn();
    while (fnCondition(result)) {
      await wait(ms);
      result = await fn();
    }
    return result;
  };

  const wait = function (ms = 1000) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  };

  const onVidPress = async (msg) => {

      if (!typeLoad) {
        try {
          const newMsg = {
            "text": msg,
            "id": messages.length + 1,
            "sender": {
              "name": "Me",
              "uid": "user1",
              "avatar": userHuman,
            },
          };

          setMessages([...messages, newMsg]);

          rqId = uuidv4();
          setTypeLoad(element);
          console.log(msg);

          // this will handle send request
          const post_resp = await axios.post(`${kishiUrl}/chat_server/run`,
            {input: {speech: msg, request_id: rqId, username: userName}},
            {
              headers: {
                'Authorization': accessToken,
                'apiKey': `Bearer ${apiKey}`,
              }
            }
          );
          // if IN_QUEUE, poll. else throw Error
          console.log(post_resp);

          let queueID = post_resp.data.id
          let fetchReport = () => axios.get(`${kishiUrl}/chat_server/status/${queueID}`,
            {
              headers: {
                'Authorization': accessToken,
                'apiKey': `Bearer ${apiKey}`,
              }
            });
          let validate = result => !(["COMPLETED", "FAILED"].includes(result.data.status));
          let resp = await poll(fetchReport, validate, 500);

          if (resp.data.status === "FAILED" || "error" in resp.data.output || !("answer" in resp.data.output)) {
            console.log(resp.data.output.error);
            throw new Error(resp.data.output.error);
          }

          const aiMsg = {
            "text": resp.data.output.answer,
            "id": messages.length + 2,
            "sender": {
              "name": "Aera",
              "uid": "user0",
              "avatar": userAi,
            },
          };

          // this will handle get video
          const videoResp = await axios.get(`${kishiUrl}/chat/video/`, {
            params: {
              username: userName,
              request_id: rqId,
            },
            headers: {
              'Authorization': accessToken,
            }
          });

          setTypeLoad(null);

          setVideoSource(videoResp.data.url);
          setMessages([...messages, newMsg, aiMsg]);
          setLoop(false);
          setMute(false);
          vidRef.current?.play();
          vidRef.current.onended = () => {
            setVideoSource(initialVideo);
            setLoop(true);
            setMute(true);
          }
        } catch
          (error) {

          const newMsg = {
            "text": msg,
            "id": messages.length + 1,
            "sender": {
              "name": "Me",
              "uid": "user1",
              "avatar": userHuman,
            },
          };

          const errorMsg = {
            "text": "Sorry, I didn't get your question. Can you repeat what you just said?",
            "id": messages.length + 2,
            "sender": {
              "name": "Aera",
              "uid": "user0",
              "avatar": userAi,
            },
          };
          setMessages([...messages, newMsg, errorMsg]);
          setTypeLoad(null);

        }
      }

    }
  ;

  const element = <p className="chat-bubble-row"><span className="is-other w-100">Aera is typing <Loader size={3}
                                                                                                         distance={2.4}/></span>
  </p>;

  useEffect(() => {
    console.log("Next");
    console.log();
    vidRef.current?.load();
    vidRef.current?.play();
  }, [vidRef, videoSource]);

  return (
    <Wrapper className='full-page'>
      <video className={styles.videoStyleBg} autoPlay playsInline muted={mute} loop={looping}>
        <source src={bgVideo} type="video/mp4"/>
        <source src={bgVideo2} type="video/mp4"/>
      </video>
      <img src={bgImg} className={styles.bgImage} alt="bg"/>
      <div className="form" style={{backgroundColor: "white", opacity: 1}}>
        <Container>
          <Row>
            <Col lg={5} md={6}>
              <video className={styles.videoStyleInner} ref={vidRef} src={videoSource} width='650'
                     autoPlay={true}
                     playsInline
                     muted={mute}
                     loop={looping}/>
            </Col>
            <Col lg={7} md={6} className={styles.messageBox}>
              <ChatBox messages={messages} onSubmit={onVidPress} isLoading={chatLoad}
                       typingIndicator={typeLoad} disabled={true}/>
            </Col>
          </Row>
        </Container>
      </div>
      <p className="mx-auto my-auto text-style-13">© Copyright 2023 - Kishi AI</p>
    </Wrapper>
  );
}

export default Run;
