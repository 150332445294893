import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Landing, Error, Run, ProtectedRoute} from './pages';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route
          path='/run'
          element={
            <ProtectedRoute>
              <Run/>
            </ProtectedRoute>
          }
        >
        </Route>
        <Route path='*' element={<Error/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
