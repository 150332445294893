import styled from 'styled-components'

const Wrapper = styled.main`
  nav {
    width: var(--fluid-width);
    max-width: var(--max-width);
    margin: 0 auto;
    height: var(--nav-height);
    display: flex;
    align-items: center;
  }

  .page {
    min-height: calc(100vh - var(--nav-height));
    display: block;
    align-items: center;
    margin-top: -3rem;
  }

  h1 {
    font-weight: 700;

    span {
      color: var(--primary-500);
    }
  }

  p {
    color: var(--grey-600);
  }

  .main-img {
    display: none;
  }

  #myVideo {
    z-index: -10;
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 0.8;
  }

  #personVideo {
    z-index: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    max-width: 30%;
    min-height: 10%;
    opacity: 1;
  }

  #backgroundImage {
    z-index: -5;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    max-height: 100%;
    opacity: 0.4;
  }

  .contentContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  #meetText {
    width: 100%;
    margin: inherit;
    font-weight: 100;
    letter-spacing: 0.8rem;
  }

  #friendText {
    width: 100%;
    margin: inherit;
    font-weight: 200;
  }

  #captionText {
    width: 100%;
    margin: inherit;
    font-weight: 100;
  }

  #aeraImage {
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 28%;
  }
  
  #linkText {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    .page {
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
    }

    .main-img {
      display: block;
    }

    #aeraImage {
      width: 50%;
    }
    #myVideo{
      height: 100%;
    }
  }
`
export default Wrapper
