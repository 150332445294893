import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser, logoutUser} from "../features/user/userSlice";
import {useEffect, useState} from "react";


const ProtectedRoute = ({children, location}) => {
  const dispatch = useDispatch();

  // redirect to login
  const queryParams = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    response_type: "token",
    scope: process.env.REACT_APP_COGNITO_SCOPES,
    redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT
  }
  const cognitoUiUrl = new URL(process.env.REACT_APP_COGNITO_UI) + "?" + decodeURIComponent(new URLSearchParams(queryParams).toString());

  // get USER
  const {user} = useSelector((store) => store.user);
  //TODO: need to check if token expired

  //check if user has just been logged in
  const navigate = useNavigate();
  const hash = useLocation().hash;
  let userDetail = null;

  const parseJwt = (token) => {
    if (!token) {
      return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  useEffect(() => {
    if (hash) {
      let h = hash.substring(1);
      const hashDict = h.split("&").map(v => v.split("=")).reduce((pre, [key, value]) => ({...pre, [key]: value}), {});

      userDetail = parseJwt(hashDict?.id_token);
      if (userDetail) {
        userDetail.token = hashDict?.id_token;
        dispatch(updateUser(userDetail));
      }
    }

    if (Math.floor(Date.now() / 1000) >= user?.exp) {
      dispatch(logoutUser());
      window.location.replace(cognitoUiUrl);
      //need to reset if token is expired
    }

    if (!user && !userDetail) {
      window.location.replace(cognitoUiUrl);
    }

  }, [userDetail, hash, navigate, dispatch]);

  return children;
};
export default ProtectedRoute;
